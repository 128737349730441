import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/dataFetch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuth } from '../../utils/auth';
import { Routes, Route, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },

    cardHeader: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    margin:{
        marginLeft: '300px',
    }
}));

// The array to store the "boxes" which will be display on the page.
const tiers = [];

/**
 * Component Gaz:
 *      Display the value for the Gaz
 */
export default function Gaz() {

    const classes = useStyles();

    const { url, sensorName } = useAuth();
    let { capteur, id } = useParams(); 

    // To force the rerender (force the update)
    const [, setUpdate] = useState();

    const [NO2_25, setNO2_25] = useState(0);
    const [NO2_360, setNO2_360] = useState(0);

    const [NO2_index_25, setNO2_index_25] = useState(0);
    const [NO2_index_360, setNO2_index_360] = useState(0);

    const [COVT_index_25, setCOVT_index_25] = useState(0);
    const [COVT_index_360, setCOVT_index_360] = useState(0);


    useEffect(() => {
        // Empty the arrays because otherwise adds boxes without deleting the others. Because it reexcuted every time that the url change.
        tiers.length = 0;

        // Add the parameters mesured by the sensor in the tiers arrays.
        if (sensorName === "nexity") {
            tiers.push({
                title: "NO2",
                description: ['', '', '', ''],
            });

            //Fetch the data
            fetchData(url, `/value/${capteur}/${id}/no2/25`, setNO2_25);
            fetchData(url, `/value/${capteur}/${id}/no2/360`, setNO2_360);

            // Modify the description.
            tiers[0].description[0] = "25min : " + NO2_25 + " µg/m3";

            tiers[0].description[2] = "6h : " + NO2_360 + " µg/m3";
        }
        if(sensorName === "belouga") {
            tiers.push({
                title: "NO2",
                description: ['', '', '', ''],
            });

            tiers.push({
                title: "NO2 Index",
                description: ['', '', '', ''],
            });

            tiers.push({
                title: "COVT index",
                description: ['', '', '', ''],
            });

            //Fetch the data
            fetchData(url, `/value/${capteur}/${id}/no2/25`, setNO2_25);
            fetchData(url, `/value/${capteur}/${id}/no2/360`, setNO2_360);
            fetchData(url, `/value/${capteur}/${id}/covt_s_index/25`, setCOVT_index_25);
            fetchData(url, `/value/${capteur}/${id}/covt_s_index/360`, setCOVT_index_360);
            fetchData(url, `/value/${capteur}/${id}/no2_s_index/25`, setNO2_index_25);
            fetchData(url, `/value/${capteur}/${id}/no2_s_index/360`, setNO2_index_360);

            // Modify the description.
            tiers[0].description[0] = "25min : " + NO2_25 + " µg/m3";

            tiers[0].description[2] = "6h : " + NO2_360 + " µg/m3";

            tiers[1].description[0] = "25min : " + NO2_index_25 + " µg/m3";

            tiers[1].description[2] = "6h : " + NO2_index_360 + " µg/m3";

            tiers[2].description[0] = "25min : " + COVT_index_25 + " µg/m3";

            tiers[2].description[2] = "6h : " + COVT_index_360 + " µg/m3";
        }
        // Mettre la partie BELOUGA

        // Forcer render
        setUpdate({});

    }, [url, NO2_25, NO2_360, COVT_index_25, COVT_index_360, NO2_index_25, NO2_index_360]);


    return (
        <React.Fragment>
            <CssBaseline />
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography variant="h4" align="center" color="textPrimary" component="p">
                    Capteur de gaz
        </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map(tier => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} md={4}>
                            <Card variant='outlined'>
                                <CardHeader
                                    title={tier.title}
                                    titleTypographyProps={{ align: 'center' }}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <ul>
                                        {tier.description.map((line, index) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={index}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}