/**
 * Check the status of a response
 * @param {promise} res - Status of the request
 * @return {promise} - Return ok (code  200) if ok else the error message
 */
export const checkStatus = (res) => {
    if (res.ok) {
      return res;
    } else {
      return res.text().then((msg) => {
        throw new Error(msg);
      });
    }
  };
  