import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router";
import logo from "../images/AirEtD.png";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {useAuth} from '../utils/auth'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: "#3c4252",
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
        align: "center"
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: "white",
    },
    logo: {
        maxWidth: 140,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textColor: {
        color: "white",
    },
    title: {
        marginLeft: "4%",
        color: "white",
    },
    paper: {
        position: 'absolute',
        width: 550,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    jss11: {
        width: 15
    }
}));

/**
 * Component Bar
 *      Display the navbar of the app.
 */
export default function Bar() {
    const classes = useStyles();
    let params = useParams();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [error, setError] = useState("");
    const [modalStyle] = useState(getModalStyle);
    const [heureDebut, setHeureDebut] = useState(null);
    const [heureFin, setHeureFin] = useState(null);
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const [unite, setUnite] = useState('minutes');
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };
    const [interval, setInterval] = useState(0);

    const handeChangeSelect = (event) => {
        setUnite(event.target.value);
    }

    const {url} = useAuth();

    /*useEffect(() => {
        fetch("/all-sensors/"+params.id)
        .then(checkStatus)
            .then((res) => res.json())
            .then((sensor) => setSensors(sensor))
            .catch(() => console.log("Erreur"))
    }, []);*/
    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    let manageExport = (e) => {
        e.preventDefault();
        switch (value) {
            case 0:
                if (dateDebut == null || dateFin == null || heureDebut == null || heureFin == null) {
                    setError("Les dates et heures ne peuvent pas être vides, veuillez rentrer une date valide avec le bon format")
                }
                else if (Date.parse(dateDebut + 'T' + heureDebut) > Date.parse(dateFin + 'T' + heureFin) || Date.parse(dateDebut + 'T' + heureDebut) > new Date().getTime() || Date.parse(dateFin + 'T' + heureFin) > new Date().getTime()) {
                    setError("Les dates renseignées sont incorrectes");
                }
                else {
                    //document.location.href = ``;

                    fetch(`${url}/download/${params.capteur}/${params.id}?dateDebut=${dateDebut + 'T' + heureDebut}&dateFin=${dateFin + 'T' + heureFin}`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        responseType: 'blob' // Set response type as blob
                      })
                      .then((res) => res.blob())
                      .then((blob) => {
                        const url = URL.createObjectURL(blob);
                      
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'data.csv'; // Set the desired file name
                      
                        // Programmatically click the link to trigger the download
                        link.click();
                      
                        // Clean up the URL object after the download
                        URL.revokeObjectURL(url);
                      });

                    setError("");
                    handleClose();
                    setHeureDebut(null)
                    setHeureFin(null)
                    setDateDebut(null)
                    setDateFin(null)
                }
                break;
            case 1:

                fetch(`${url}/download/${params.capteur}/${params.id}?interval=${interval} ${unite}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    responseType: 'blob' // Set response type as blob
                  })
                  .then((res) => res.blob())
                  .then((blob) => {
                    const url = URL.createObjectURL(blob);
                  
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'data.csv'; // Set the desired file name
                  
                    // Programmatically click the link to trigger the download
                    link.click();
                  
                    // Clean up the URL object after the download
                    URL.revokeObjectURL(url);
                  });



                break;
        }

    }
    return (
        <>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Link href={`/`}><img src={logo} alt="logo" className={classes.logo} /></Link>
                    {params.id != undefined ?
                        <h2 className={classes.title}> {`${params.capteur.charAt(0).toUpperCase() + params.capteur.slice(1)} ${params.id}`}</h2>
                        : <></>
                    }
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                    </Typography>
                    {params.capteur && 
                        <nav>
                            <Link variant="button" color="textPrimary" href={`/${params.capteur}/${params.id}`} className={classes.link}>
                                Mesures
                            </Link>
                            <Link variant="button" color="textPrimary" href={`/chart-belouga/${params.capteur}/${params.id}`} className={classes.link}>
                                Graphiques
                            </Link>
                            <Link variant="button" color="textPrimary" onClick={handleOpen} className={classes.link} style={{'cursor':'pointer'}}>
                                Exporter
                            </Link>
                        </nav>
                    }

                    <Button 
                        variant="contained"
                        onClick={() => {
                            localStorage.removeItem('token')
                            window.location.reload()
                        }}    
                    >Déconnexion</Button>

                </Toolbar>
            </AppBar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Exporter des données</h2>
                    <div className={classes.root}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                                <Tab label="Entre deux dates" {...a11yProps(0)} />
                                <Tab label="intervalle de temps" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <form onSubmit={manageExport}>
                                <p id="simple-modal-description">
                                    <TextField
                                        id="date"
                                        label="Date de debut"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={dateDebut}
                                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                                        onChange={e => setDateDebut(e.target.value)}
                                    />
                                    <TextField
                                        id="time"
                                        label="heure de debut"
                                        type="time"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={heureDebut}
                                        onChange={e => setHeureDebut(e.target.value)}
                                    />
                                </p>
                                <p id="simple-modal-description">
                                    <TextField
                                        id="date"
                                        label="Date de fin"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                                        value={dateFin}
                                        onChange={e => setDateFin(e.target.value)}
                                    />
                                    <TextField
                                        id="time"
                                        label="heure de fin"
                                        type="time"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={heureFin}
                                        onChange={e => setHeureFin(e.target.value)}
                                    />
                                </p>
                                <Button variant="contained" color="primary" type="submit">
                                    Exporter
                                </Button>
                            </form>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <form onSubmit={manageExport}>
                                <TextField label="intervalle" type="number" value={interval} onChange={e => setInterval(e.target.value)} />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Unité</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={unite}
                                        onChange={handeChangeSelect}
                                    >
                                        <MenuItem value='minutes'>Minutes</MenuItem>
                                        <MenuItem value='hours'>Heures</MenuItem>
                                        <MenuItem value='days'>Jour</MenuItem>
                                        <MenuItem value='months'>Mois</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color="primary" type="submit">
                                    Exporter
                                </Button>
                            </form>
                        </TabPanel>
                    </div>


                    <p style={{ color: 'red' }}>{error}</p>

                </div>
            </Modal>
        </>
    );
}
