import React from 'react';
import Bar from '../Bar';
import ParticulesFines from './ParticulesFines';
import Gaz from "./Gaz";
import TH from "./TH";
//import Footer from "../Footer";
import { useAuth } from "../../utils/auth";
//import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";


/**
 * Component: HomePage
 *      Display the home page with the component Bar, ParticulesFines, Gaz, TH and Footer
 */
const HomePage = () => {

    const { setHome } = useAuth();
    setHome(true);
    
    return (
        <>
            <Bar/>
            <ParticulesFines/>
            <Gaz/>
            <TH/>
        </>
    )
}

export default HomePage;