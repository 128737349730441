import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { fetchData } from "../../utils/dataFetch";

import { useAuth } from '../../utils/auth';
import { Routes, Route, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },

    cardHeader: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    margin:{
        marginLeft: '150px',
    }
}));

let tiers = [];

/**
 * Component: TH
 *      Display the temperature et humidity
 */
export default function TH() {

    const classes = useStyles();
    let { capteur, id } = useParams(); 
    const { url, sensorName } = useAuth();

    // To force the rerender (force the update)
    const [, setUpdate] = useState();

    const [temperature_25, setTemperature_25] = useState(0);
    const [temperature_360, setTemperature_360] = useState(0);
    const [humidity_25, setHumidity_25] = useState(0);
    const [humidity_360, setHumidity_360] = useState(0);

    useEffect(() => {
        // Vide le tableau car sinon rajoute des cases sans supprimer les autres
        tiers.length = 0;

        if(sensorName === "belouga")
        {
            tiers.push({
                title: 'Température',
                description: ['', '', '', ''],
            });
    
            tiers.push({
                title: 'Humidité',
                description: ['', '', '', ''],
            });

            fetchData(url, `/value/${capteur}/${id}/temperature/25`, setTemperature_25);
            fetchData(url, `/value/${capteur}/${id}/temperature/360`, setTemperature_360);
            fetchData(url, `/value/${capteur}/${id}/humidite/25`, setHumidity_25);
            fetchData(url, `/value/${capteur}/${id}/humidite/360`, setHumidity_360);

            tiers[0].description[0] = "25min : " + temperature_25 + " C°";

            tiers[0].description[2] = "6h : " + temperature_360 + " C°";

            tiers[1].description[0] = "25min : " + humidity_25 + " %";

            tiers[1].description[2] = "6h : " + humidity_360 + " %";

            // Force the render
            setUpdate({});
        }
    }, [url, temperature_25, temperature_360, humidity_25, humidity_360]);

    return (  
        <React.Fragment>
            <CssBaseline />
            {sensorName === "belouga" && <>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography variant="h4" align="center" color="textPrimary" component="p">
                    Capteur de température et d'humidité
        </Typography>
            </Container> 
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end" className={classes.margin}>
                    {tiers.map(tier => (  // Map on tier to display the informations
                        <Grid item key={tier.title} xs={12} md={4}>
                            <Card variant='outlined'>
                                <CardHeader
                                    title={tier.title}
                                    titleTypographyProps={{ align: 'center' }}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <ul>
                                        {tier.description.map((line, index) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={index}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container> 
            </> }
        </React.Fragment>
    );
            
}