import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import { checkStatus } from "../utils/checkStatus";
import Bar from "./Bar";
import Card from '@material-ui/core/Card';
import { CardContent, CardHeader, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useAuth } from '../utils/auth'

function Status() {
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
      textAlign: "center",
    },
    pos: {
      marginBottom: 12,
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.grey[700]
          : theme.palette.grey[200],
    }
  }));
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const {url} = useAuth();
  const [lastRefresh, setLastRefresh] = useState(null)


  let firstFetch = true

  let getInfo = () => {

    if (firstFetch)
      firstFetch = false

    fetch(`${url}/status`)
      .then(res => res.json())
      .then(result => {console.log(result); setItems(result); setLastRefresh(result[0].mes_dateheure)})
      .catch(() => console.log("Erreur"))

  }

  const capitalizeText = (text) => {
    return text.toLowerCase().charAt(0).toUpperCase() + (text.slice(1).toLowerCase())
  }

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString()
  }

  return (
    <>
      <Bar />

      <div className="refreshButton">
        <Button 
            variant="contained"
            onClick={() => {
                getInfo()
            }}    
        >Actualiser</Button>
        { lastRefresh && <span className="lastRefresh"> Dernière actualisation : {formattedDate(lastRefresh)} </span>}
      </div>

      {items.length == 0 && !firstFetch &&
          <div className="noDataContainer">
            <h1 className="noDataText">
              Données capteurs vides &#128683;
            </h1>
          </div>
      }


      <Grid container spacing={5} alignItems="flex-end" style={{ paddingTop: "15px" }}>
        {items.map(item => (
          <Grid item xs={12} sm={4}>
            <Card className={classes.root}>
              <CardHeader
                title={capitalizeText(item.cap_nom)}
                titleTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              >
              </CardHeader>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  <p>{`Dernière mesure - ${formattedDate(item.avg)}`}</p>
                  <p><b>PM1 : </b> {item.mes_pm1 + ''} <b>PM2_5 : </b>{item.mes_pm2_5} <b>PM10 : </b> {item.mes_pm10}</p>
                  <p><b>PM1 (small) : </b> {item.mes_pm1_s + ''} <b>PM2_5 (small) : </b>{item.mes_pm2_5_s} <b>PM10 (small) : </b> {item.mes_pm10_s}</p>
                  <p><b>NO2 : </b> {item.mes_no2} <b>NO2 Index:</b> {item.mes_no2_s_index}</p>
                  <p><b>COVT Index : </b> {item.mes_covt_s_index}</p>
                  <p><Link href={`/${item.cap_modele}/${item.cap_nom.replace(item.cap_modele, "")}`}>Voir le detail</Link></p>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Status;