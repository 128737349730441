import { checkStatus } from "./checkStatus";
import { convertDate } from "./convertDate";
import { Routes, Route, useParams } from 'react-router-dom';
import {useAuth} from './auth'

/**
 * Fetch the data for the chart.
 * @param {string} val - The things we want to retrieve : Example : PM10.
 * @param {number} time - The time duration we want for the graphs : Example : 25 (minutes).
 * @param {string} sensor - The name of the sensor (cozy or nanosense).
 * @return {Array} - Return an Array with the data for the plots.
 */
export const fetchDataChart = (val, time, capteur, id, url) => {

    return new Promise((resolve, reject) => {
        let route = `${url}/chart/${capteur}/${id}/${val}/${time}`;
        fetch(route)
            .then(checkStatus)
            .then(res => res.json())
            .then(mesure => {
                // Map where we will store the data needed to the creation of the plot.
                let map = new Map();
                
                // Array to store the dates of all the sensors.
                let xaxis = [];

                for (let i = 0; i < mesure.length; i++) {
                    // Creation of the keys of the map and insertion of the data.
                    //let name = mesure[i].nom + "_" + mesure[i].id;
                    let name = mesure[i].id;

                    if (!map.has(name)) { // Test if the key name not already exist.
                        // Set the key name with an object as value.
                        map.set(name, { x: [], y: [], type: 'scatter', name: name });
                        // Push data in the x and y arrays. x are the values from the xaxis and y the values from the yaxis.
                        map.get(name).x.push(convertDate(mesure[i].date, 2));
                        map.get(name).y.push(mesure[i].value);
                        //Add in the xaxis array the date that will be sorted and pass to the function plotParameters to have the values sorted on the graph.
                        xaxis.push(convertDate(mesure[i].date, 2));
                    }
                    else {
                        // If the key exist only add the values in the arrays for the specific key.
                        map.get(name).x.push(convertDate(mesure[i].date, 2));
                        map.get(name).y.push(mesure[i].value);
                        xaxis.push(convertDate(mesure[i].date, 2));
                    }
                }

                // Function that sort the data.
                let sortedDates = xaxis.sort(function (var1, var2) {
                    let a = new Date(var1), b = new Date(var2);
                    if (a > b)
                        return 1;
                    if (a < b)
                        return -1;                
                    return 0;
                });
                
                // Arrays to store the data for the different plots.
                let plot = [];

                // Iterate the map to fill in the plot array
                for (let value of map.values()) {
                    plot.push(value);
                }
                resolve([plot, sortedDates]);
            })
            .catch(() => reject());
    })
}