import React, { useEffect, useState } from "react";
import { checkStatus } from "../utils/checkStatus";
import Bar from "./Bar";

function LastData() {

    const [items, setItems] = useState([]);
    useEffect(() => { //lancement au debut de l'application
        fetch("/last-data")
        .then((res) => res.json())
        .then((result)=>setItems(result))
        .catch(() => console.log("Erreur"))
    }, []);
    console.log(items);
    return (
    <>
    <Bar/>
     <ul>
         {items.map(item=>
            <li>{item.nom} {item.id} à {item.date} mesure : NO2 : {item.no2} PM1 :  {item.pm1} pm2,5: {item.pm2_5} PM10: {item.pm10} </li>)}
    </ul>   
        
    </>
    );
}

export default LastData;