import React, {useState, useEffect} from 'react';
import './Login.css';
import PropTypes from 'prop-types';

async function loginUser(credentials) {
  return fetch('https://api.airpad.fr/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        return { error: data.error };
      }
      console.log(data)
      return { token: data }; // Update to access_token
    })
    .catch(err => {
      console.log(err);
      return { error: 'An error occurred during login' };
    });
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const {error, token} = await loginUser({
      username,
      password
    });

    if (token.msg) {
      setError(token.msg);
    } else {
      setToken(token);
      console.log(token)
      localStorage.setItem('token', token);
    }
  }

  return (
    <div className="background">
      <div className="login-wrapper">
        <h1>Connexion</h1>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <label>
              <input type="text" onChange={e => setUserName(e.target.value)} placeholder="Nom d'utilisateur" />
            </label>
            <label>
              <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Mot de passe" />
            </label>
          </div>
          <div className="submitContainer">
            <button type="submit" className="submit">Se connecter</button>
          </div>
        </form>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  )
}


Login.propTypes = {
    setToken: PropTypes.func.isRequired
  };