import { checkStatus } from './checkStatus';

/**
 * Fetch the data for a specific mesure
 * @param {string} url - Begin of the url ([domainName]/sensor/sensorId) you don't have to specifie the domainName react will handle it without problem.
 * @param {string} path -  End of the url (valueToMesure/TimeInterval)
 * @param {function} funct - the setState function to change the state of a useState variable
 */
export const fetchData = (url, path, funct) => {
  fetch(url + path)
    .then(checkStatus)
    .then((res) => res.json())
    .then((mesure) => {
      if(Object.keys(mesure).length !== 0){
        // Round the result.
        funct(Math.round(mesure[0].avg * 100) / 100);
      }
      else{
        funct(0);
      }
    })
    .catch(() => {
      funct(0);
      console.log('Erreur');
    });
};