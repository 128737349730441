import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/auth';
import { convertDate } from '../../utils/convertDate'
import { fetchData } from '../../utils/dataFetch'
import { checkStatus } from '../../utils/checkStatus';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Routes, Route, useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },

  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: 50,
  }
}));

// The array to store the "boxes" which will be display on the page.
let tiers = [];

/**
 * Component ParticulesFines:
 *    Display the value of air pollution particule
 */
export default function ParticulesFines() {
  const classes = useStyles();
  const { url, sensorName } = useAuth();
  let { capteur, id } = useParams(); 

  // Pour forcer le rerender (forcer l'update)
  const [, setUpdate] = useState();

  const [timeBegin_25, setTimeBegin_25] = useState(undefined);
  const [timeBegin_360, setTimeBegin_360] = useState(undefined);
  const [timeEnd, setTimeEnd] = useState(undefined);

  const [PM1_25, setPM1_25] = useState(0);
  const [PM1_360, setPM1_360] = useState(0);

  const [PM2_5_25, setPM2_5_25] = useState(0);
  const [PM2_5_360, setPM2_5_360] = useState(0);

  const [PM10_25, setPM10_25] = useState(0);
  const [PM10_360, setPM10_360] = useState(0);

  const [PM1_s_25, setPM1_s_25] = useState(0);
  const [PM1_s_360, setPM1_s_360] = useState(0);

  const [PM2_5_s_25, setPM2_5_s_25] = useState(0);
  const [PM2_5_s_360, setPM2_5_s_360] = useState(0);

  const [PM10_s_25, setPM10_s_25] = useState(0);
  const [PM10_s_360, setPM10_s_360] = useState(0);

  // Function to fetch the data of PM2.5 and the dates to display on the home page
  const pm2_5Date = (url, path, functPM2_5, functTimeBegin, functTimeEnd = undefined) => {
    console.log(url + path);
    fetch(url + path)
      .then(checkStatus)
      .then(res => res.json())
      .then((mesure) => {
        functPM2_5(Math.round(mesure[0].avg * 100) / 100);
        functTimeBegin(convertDate(mesure[0].debut, 1));
        //To avoid looking twice for the end time.
        if (typeof functTimeEnd !== "undefined") {
          functTimeEnd(convertDate(mesure[0].fin, 1));
        }
      })
      .catch(() => {
        functPM2_5(0);
        functTimeBegin(undefined);
        // Otherwise it will crash because the default value undefined is not a function.
        if (typeof functTimeEnd !== "undefined") {
          functTimeEnd(undefined);
        }
        console.log('Erreur');
      });
  }

  useEffect(() => {
    // Vide le tableau car sinon rajoute des cases sans supprimer les autres
    tiers.length = 0;

    if (sensorName === "nexity") {
      tiers.push({
        title: 'PM2.5',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM10',
        description: ['', '', '', ''],
      });

      // fetch the data
      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5/25`, setPM2_5_25, setTimeBegin_25, setTimeEnd);
      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5/360`, setPM2_5_360, setTimeBegin_360);
      fetchData(url, `/value/${capteur}/${id}/pm10/25`, setPM10_25);
      fetchData(url, `/value/${capteur}/${id}/pm10/360`, setPM10_360);

      // Modify the description.
      tiers[0].description[0] = '25min : ' + PM2_5_25 + ' µg/m3';

      tiers[0].description[2] = '6h : ' + PM2_5_360 + ' µg/m3';

      tiers[1].description[0] = '25min : ' + PM10_25 + ' µg/m3';

      tiers[1].description[2] = '6h : ' + PM10_360 + ' µg/m3';
    }
    if (sensorName === "belouga") {
      tiers.push({
        title: 'PM1',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM2.5',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM10',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM1 (Small)',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM2.5 (Small)',
        description: ['', '', '', ''],
      });

      tiers.push({
        title: 'PM10 (Small)',
        description: ['', '', '', ''],
      });

      // fetch the data
      fetchData(url, `/value/${capteur}/${id}/pm1/25`, setPM1_25);
      fetchData(url, `/value/${capteur}/${id}/pm1/360`, setPM1_360);
      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5/25`, setPM2_5_25, setTimeBegin_25, setTimeEnd);
      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5/360`, setPM2_5_360, setTimeBegin_360);
      fetchData(url, `/value/${capteur}/${id}/pm10/25`, setPM10_25);
      fetchData(url, `/value/${capteur}/${id}/pm10/360`, setPM10_360);

      fetchData(url, `/value/${capteur}/${id}/pm1_s/25`, setPM1_s_25);
      fetchData(url, `/value/${capteur}/${id}/pm1_s/360`, setPM1_s_360);

      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5_s/25`, setPM2_5_s_25, setTimeBegin_25, setTimeEnd);
      pm2_5Date(url, `/value/${capteur}/${id}/pm2_5_s/360`, setPM2_5_s_360, setTimeBegin_360);

      fetchData(url, `/value/${capteur}/${id}/pm10_s/25`, setPM10_s_25);
      fetchData(url, `/value/${capteur}/${id}/pm10_s/360`, setPM10_s_360);

      // Modify the description.
      tiers[0].description[0] = '25min : ' + PM1_25 + ' µg/m3';

      tiers[0].description[2] = '6h : ' + PM1_360 + ' µg/m3';

      tiers[1].description[0] = '25min : ' + PM2_5_25 + ' µg/m3';

      tiers[1].description[2] = '6h : ' + PM2_5_360 + ' µg/m3';

      tiers[2].description[0] = '25min : ' + PM10_25 + ' µg/m3';

      tiers[2].description[2] = '6h : ' + PM10_360 + ' µg/m3';

      tiers[3].description[0] = '25min : ' + PM1_s_25 + ' µg/m3';

      tiers[3].description[2] = '6h : ' + PM1_s_360 + ' µg/m3';

      tiers[4].description[0] = '25min : ' + PM2_5_s_25 + ' µg/m3';

      tiers[4].description[2] = '6h : ' + PM2_5_s_360 + ' µg/m3';

      tiers[5].description[0] = '25min : ' + PM10_s_25 + ' µg/m3';

      tiers[5].description[2] = '6h : ' + PM10_s_360 + ' µg/m3';
    }

    // Ajouter ppur BELOUGA 

    // Force the render
    setUpdate({});

  }, [url, PM1_25, PM1_360, PM2_5_25, PM2_5_360, PM10_25, PM10_360, PM1_s_25, PM1_s_360, PM2_5_s_25, PM2_5_s_360, PM10_s_25,PM10_s_360]);

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom>
          Mesures
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
          gutterBottom>
          Plage d'une durée de 25 minutes : <br></br> {timeBegin_25} - {timeEnd}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
          gutterBottom>
          Plage d'une durée de 6 heures : <br></br> {timeBegin_360} - {timeEnd}
        </Typography>
        <Typography
          className={classes.title}
          variant="h4"
          align="center"
          color="textPrimary"
          component="p">
          Capteur de particules fines
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <ul>
                    {tier.description.map((line, index) => (  // Map on tier to display the informations
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={index}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
