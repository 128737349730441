/**
 * Create all the information necessary to create the plot.
 * @param {Array[Array[object], string]} value - The date for the graph.
 * @param {string} title - Title of the plot.
 * @param {string} y_axis  - Text to display on the y_axis.
 * @param {Array[String]} sortedDates - The dates of all the sensors sorted for the graph to be well created.
 * @return {Array[object, object, object]} - An array of objects.
 */
export const plotParameters = (value, title, y_axis, sortedDates, dynamique, min, max) => {

  // Layout parameters for the plot
  const isDynamique = !dynamique ? {
    range: [min, max],
    title: {
      text: y_axis
    }
  } : {
    automargin: true,
    title: {
      text: y_axis
    }
  };

  let layout = {
    title: title,
    width: "45%",
    height: "100%",
    xaxis: {
      automargin: true,
      type: "category",
      categoryorder: 'array',
      categoryarray: sortedDates
    },
    yaxis: isDynamique
  };

  // Made the graph responsive on windows resize.
  var config = { responsive: true }

  return [value, layout, config];
}