import React, {useState, useEffect} from 'react';
import { AuthProvider } from '../utils/auth';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./home/HomePage";
import ChartBelouga from "./plot/ChartBelougaPage";
import LastData from "./LastData";
import StatusPage from './StatusPage';
import Login from './login/Login';

/**
 * Component: App
 *    Entry point of the code.
 */
function App() {
  const [token, setToken] = useState(() => localStorage.getItem('token'));

  if(!token || token == null || token == 'undefined') {
    return (
      <Router> 
        <Login setToken={setToken} />
      </Router>
    )
  }

  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/sensor_lastData" component={LastData} />
          <Route exact path="/:capteur/:id" component={Home} />
          <Route exact path="/chart-belouga/:capteur/:id" component={ChartBelouga} />
          <Route exact path="/" component={StatusPage}></Route>
          <Route exact path="/sensor_export"></Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;