/**
 * Convert a given date to a specific format
 * @param {string} date - The date with time zone to convert
 * @param {number} mode - if 1 return a date to format : "lundi 15 juin 2020 à 10h30", if 2 return a date to format : "dd/MM à HH:mm"
 * @return {string} - The date once converted
 */
export const convertDate = (date, mode) => {
    
    // Month begins to 0 it's why we do -1
    const dateToConvert = new Date(date);

    //Options to pass to the function toLocalDateString (Will be initialise later).
    let options;

    if(mode === 1){
        options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        // Date to the format : mercredi 15 juillet 2020 à 09:02
        return dateToConvert.toLocaleDateString('fr-FR', options); 
    }
    else if(mode === 2){
        options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        // Date to format : 15-07-2020 à 09:05
        return dateToConvert.toLocaleDateString('fr-FR', options);
    }
}
