import React, { useEffect, useRef } from "react";
import { useAuth } from '../../utils/auth';
import { fetchDataChart } from "../../utils/fetchDataChart";
import { plotParameters } from "../../utils/plotParameters";
import { Routes, Route, useParams } from 'react-router-dom';
import Plotly from 'plotly.js-dist-min'



/**
 * Component: Chart
 *    Display a div containing the plot.
 * 
 * props:
 *    - div : Name of the div to create.
 *    - sensor: Name of the sensors (cozy or nanosense).
 *    - val: Parameters from which we want the values.
 *    - title: Title of the plot.
 *    - y_axis: Text to display on the y axis
 */
const Chart = ({ div, val, title, y_axis, min, max }) => {

  const { time, dynamique, url } = useAuth();
  const {capteur, id} = useParams();

  const firstUpdate = useRef(true);

  useEffect(() => {
    fetchDataChart(val, time,capteur, id, url)
    .then(([plot, sortedDates]) => {
        // Wait that the promise fetchDataChart is complete to continue.

        // Retrieve the data needed to create the plot.
        let chart = plotParameters(plot, title, y_axis, sortedDates, dynamique, min, max);

        if (firstUpdate.current) { // Check if it the first time that the useEffect is execute.
          firstUpdate.current = false;
          // Create the plot
          Plotly.newPlot(div, chart[0], chart[1], chart[2]);
        }
        else {
          // Purge the plot and recreate it.
          Plotly.purge(div);
          Plotly.newPlot(div, chart[0], chart[1], chart[2]);
        }
      })

  }, [time, dynamique]);

  return (
    <>
      <div id={div} className="div_chart"></div>
    </>
  )
}

export default Chart;