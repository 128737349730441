import React, { useEffect, useState } from "react";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { useAuth } from '../../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        margin: "1%",
        marginLeft: "4%"
    },
}));

/**
 * Component: ButtonChart
 *      Display button used to change xaxis of the plots.
 */
const ButtonChart = () => {
    const classes = useStyles();
    const { time, setTime, dynamique, setDynamique } = useAuth();

    // The different values available for time in minutes
    const TIMES = [25, 60, 360, 720, 1440];

    return (
        <div className={classes.root} >
            {TIMES.map(t => (
                <Button
                    key={t}
                    variant="contained"
                    disabled={time === t}
                    onClick={() => setTime(t)}>
                    {t < 60 ? `${t}min` : `${t / 60}h`}
                </Button>
            ))}
            <FormControlLabel
                control={
                    <Switch
                        checked={dynamique}
                        onChange={() => { setDynamique(current => !current) }}
                        color="Primary"
                    />
                }
                label="Dynamique"
            />
        </div>
    )
}

export default ButtonChart;