import React, { createContext, useContext, useState } from 'react';
import { useParams } from "react-router";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  // Name and id of the sensor used to create the url to fetch the data from the home page.
  const name = window.location.href.split('/')[3];
  const [sensorName, setSensorName] = useState(name);
  let id = window.location.href.split('/')[4];
  const [sensorId, setSensorId] = useState(id);
  // Url use to fetch the data for the home page.
  const [url, setUrl] = useState(
    `https://api.airpad.fr/api/mesures`
  );

  // Boolean to know if you are on the home page.
  // Used to know if you display the name of the sensors on the home page.
  const [home, setHome] = useState(true);

  // Time variable used for the graphs to change the xaxis
  const [time, setTime] = useState(25);

  const [dynamique, setDynamique] = useState(false);
  return (
    <AuthContext.Provider value={{ url, setUrl, sensorId, setSensorId, sensorName, setSensorName, time, setTime, home, setHome, dynamique, setDynamique }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
