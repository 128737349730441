import React from 'react';
import Bar from "../Bar";
import ButtonChart from './utils/ButtonChart';
import Chart from './Chart';
import { useAuth } from "../../utils/auth";
import { makeStyles } from '@material-ui/core/styles';

/**
 * Component: ChartPageNexity
 *      The page containing the plots of Nexity.
 */
const ChartBelougaPage = () => {

    const { setHome } = useAuth();

    // To hide the sensors in the navbar
    setHome(false);
    return (
        <>
            <Bar />
            <ButtonChart />
            <div className="div_chart">
                <Chart div="pm1_belouga" val="pm1" title="Graphique représentant les PM1 en fonction du temps" y_axis="PM1 (µg/m3)" min="0" max="80" />
                <Chart div="pm2.5_belouga" val="pm2_5" title="Graphique représentant les PM2.5 en fonction du temps" y_axis="PM2.5 (µg/m3)" min="0" max="80" />
                <Chart div="pm10_belouga" val="pm10" title="Graphique représentant les PM10 en fonction du temps" y_axis="PM10 (µg/m3)" min="0" max="80" />
                <Chart div="pm1_s_belouga" val="pm1_s" title="Graphique représentant les PM1 (petit capteur) en fonction du temps" y_axis="PM1 (µg/m3)" min="0" max="80" />
                <Chart div="pm2.5_s_belouga" val="pm2_5_s" title="Graphique représentant les PM2.5 (petit capteur) en fonction du temps" y_axis="PM2.5 (µg/m3)" min="0" max="80" />
                <Chart div="pm10_s_belouga" val="pm10_s" title="Graphique représentant les PM10 (petit capteur) en fonction du temps" y_axis="PM10 (µg/m3)" min="0" max="80" />
                <Chart div="no2_belouga" val="no2" title="Graphique représentant le NO2 en fonction du temps" y_axis="NO2 (µg/m3)" min="0" max="80" />
                <Chart div="no2_index_belouga" val="no2_s_index" title="Graphique représentant l'index de NO2 en fonction du temps" y_axis="NO2 Index" min="0" max="2" />
                <Chart div="covt_index_belouga" val="covt_s_index" title="Graphique représentant l'index de COVT en fonction du temps" y_axis="COVT Index" min="0" max="500" />
                <Chart div="temp_belouga" val="temperature" title="Graphique représentant la température en fonction du temps" y_axis="Température (C°)" min="-10" max="40" />
                <Chart div="hum_belouga" val="humidite" title="Graphique représentant l'humidité en fonction du temps" y_axis="Humidité (%)" min="0" max="100" />
                
            </div>
        </>
    )
}

export default ChartBelougaPage;
